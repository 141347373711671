@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,800;1,300&family=Sofia+Sans+Extra+Condensed:ital,wght@0,300;0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,800;1,300&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,400&family=Sofia+Sans+Extra+Condensed:ital,wght@0,300;0,400;0,700;1,400&display=swap');

@font-face {
  font-family: Sofia Sans Extra Condensed;
  src: url("../src/fonts/Sofia_Sans_Extra_Condensed/SofiaSansExtraCondensed-VariableFont_wght.ttf");
}

@font-face {
  font-family: Roboto Condensed;
  src: url("../src/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf");
}





