.letters{
    font-size: clamp(0.8rem, 1.7rem, 1.9rem);
    white-space: pre-wrap;
}

div {
    white-space: pre-wrap;
}

.subut {
    width: 30vw;
    font-size: 1.4rem;
    color: white;
    background-color: #fca4b6;
    opacity: 70%;
}
.subut:hover {
             color: #c700c7;
             cursor: pointer;
         }
